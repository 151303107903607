import Container from 'typedi'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import ReportService from '../services/report.service'
import { PlatformReportsChartDataTypesEnum } from '@/modules/level-one/enums/platform-reports-chart-data-types.enum'
import { PlatformReportsLeaderboardsType } from '@/modules/level-one/types/platform-reports-leaderboards.type'
import { ICopyReportPayload, IFetchReportsForEventPayload } from '@/interfaces/reports.interface'

@Module({
  namespaced: true
})
export default class ReportStore extends VuexModule {
  reports: any[] = []
  report: any = {}
  reportSnapshot: any = {}
  reportSnapshotResult: any = {}
  reportSnapshotResultData: any[] = []
  reportSnapshotResultDownload: any = {}
  platformReportsStats: any = {}
  platformReportsChartData: any = {}
  platformReportsLeaderboards: any = {}
  @Mutation
  SET_REPORTS (payload: any) {
    this.reports = payload
  }

  @Mutation
  SET_REPORT (payload: any) {
    this.report = payload
  }

  @Mutation
  SET_REPORT_SNAPSHOT (payload: any) {
    this.reportSnapshot = payload
  }

  @Mutation
  SET_REPORT_SNAPSHOT_RESULT (payload: any) {
    this.reportSnapshotResult = payload
  }

  @Mutation
  SET_REPORT_SNAPSHOT_RESULT_DATA (payload: any) {
    this.reportSnapshotResultData = payload
  }

  @Mutation
  SET_REPORT_SNAPSHOT_RESULT_DOWNLOAD (payload: any) {
    this.reportSnapshotResultDownload = payload
  }

  @Mutation
  SET_PLATFORM_REPORTS_STATS (payload: any) {
    this.platformReportsStats = payload
  }

  @Mutation
  SET_PLATFORM_REPORTS_CHART_DATA (payload: any) {
    this.platformReportsChartData = payload
  }

  @Mutation
  SET_PLATFORM_REPORTS_LEADERBOARDS (payload: PlatformReportsLeaderboardsType) {
    this.platformReportsLeaderboards = payload
  }

  @Action({ rawError: true })
  async getPlatformReportsStats (daysBack: number) {
    const response = await Container.get(ReportService).getPlatformReportsStats(daysBack)
    this.context.commit('SET_PLATFORM_REPORTS_STATS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async exportPlatformReportsStats (daysBack: number) {
    const response: any = await Container.get(ReportService).exportPlatformReportsStats(daysBack)
    location.href = response.data
  }

  @Action({ rawError: true })
  async getPlatformReportsChartData (payload: { daysBack: number; type: PlatformReportsChartDataTypesEnum }) {
    const { daysBack, type } = payload
    const response = await Container.get(ReportService).getPlatformReportsChartData(daysBack, type)
    this.context.commit('SET_PLATFORM_REPORTS_CHART_DATA', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getPlatformReportsLeaderboards () {
    const response = await Container.get(ReportService).getPlatformReportsLeaderboards()
    this.context.commit('SET_PLATFORM_REPORTS_LEADERBOARDS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async exportPlatformReportsChartData (payload: any) {
    const response: any = await Container.get(ReportService).exportPlatformReportsChartData(payload)
    location.href = response.data
  }

  @Action({ rawError: true })
  async exportPlatformLeaderboard (type: PlatformReportsChartDataTypesEnum) {
    const response: any = await Container.get(ReportService).exportPlatformLeaderboard(type)
    location.href = response.data
  }

  @Action({ rawError: true })
  async exportPlatformLeadsSales () {
    const response: any = await Container.get(ReportService).exportPlatformReportsLeadsSales()
    location.href = response.data
  }

  @Action({ rawError: true })
  async getReports (event_uuid: string) {
    const response = await Container.get(ReportService).getReports(event_uuid)
    this.context.commit('SET_REPORTS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async createReport (payload: any) {
    const { data, event_uuid } = payload
    const response: any = await Container.get(ReportService).addReport(data, event_uuid)
    this.context.dispatch('getReport', { event_uuid: event_uuid, report_uuid: response.data.uuid })
    return response.data
  }

  @Action({ rawError: true })
  async updateReport (payload: any) {
    const { data, event_uuid, report_uuid } = payload
    const response = await Container.get(ReportService).updateReport(data, event_uuid, report_uuid)
    this.context.dispatch('getReports', event_uuid)
    return response.data
  }

  @Action({ rawError: true })
  async deleteReport (payload: any) {
    const { event_uuid, report_uuid } = payload
    const response: any = await Container.get(ReportService).deleteReport(event_uuid, report_uuid)
    this.context.dispatch('getReports', event_uuid)
    return response
  }

  @Action({ rawError: true })
  async getReport (payload: any) {
    const { event_uuid, report_uuid } = payload
    const response = await Container.get(ReportService).getReport(event_uuid, report_uuid)
    this.context.commit('SET_REPORT', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getReportSnapshot (payload: any) {
    const { event_uuid, report_uuid, export_report } = payload
    const response: any = await Container.get(ReportService).getReportSnapshot(event_uuid, report_uuid)
    this.context.commit('SET_REPORT_SNAPSHOT', response.data)
    this.context.dispatch('getSnapshotResult', {
      event_uuid,
      report_uuid: report_uuid,
      snapshot_uuid: response.data.uuid,
      export_report
    })
    return response.data
  }

  @Action({ rawError: true })
  async getSnapshotResult (payload: any) {
    const {
      event_uuid,
      report_uuid,
      snapshot_uuid,
      export_report
    } = payload
    const response = await Container
      .get(ReportService)
      .getReportSnapshotResult(event_uuid, report_uuid, snapshot_uuid)
    if (
      response.data.status !== 'COMPLETE' &&
      response.data.status !== 'FAILED'
    ) {
      await new Promise(resolve => setTimeout(resolve, 1000))
      this.context.dispatch('getSnapshotResult', payload)
    } else {
      if (export_report) {
        location.href = response.data.url
      } else {
        this.context.commit('SET_REPORT_SNAPSHOT_RESULT', response.data)
        this.context.dispatch('getSnapshotResultData', payload)
      }
    }
    this.context.commit('SET_REPORT', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getSnapshotResultData (payload: any) {
    const {
      event_uuid,
      report_uuid,
      snapshot_uuid,
      export_report
    } = payload
    let response
    let page = 0
    const allData: any[] = []
    do {
      page++
      response = await Container.get(ReportService).getReportSnapshotResultData(event_uuid, report_uuid, snapshot_uuid, page.toString())
      for (const item in response.data.page_data.data) {
        allData.push(response.data.page_data.data[item])
      }
      this.context.commit('SET_REPORT_SNAPSHOT_RESULT_DATA', allData)
    } while (response.data.page_data.current_page < response.data.page_data.last_page)
  }

  /**
   * Fetches list of reports for target event.
   * @comment Does not store in global state, as it is not needed. At least at this time. -zb.
   * @comment in this case, I don't want to store the data. Because it could change every time an event is selected in the import modal. -zb.
   * @comment additionally, this is fetching reports for a different event. Not the current event. The data is not needed after importing an event. -zb.
   * @param {IFetchReportsForEventPayload} payload
   */
  @Action({ rawError: true })
  async fetchReportListForTargetEvent ({ target_uuid }: IFetchReportsForEventPayload): Promise<any> {
    const response = await Container.get(ReportService).getReports(target_uuid)
    return response.data
  }

  /**
   * Copies report to target event.
   * @param {ICopyReportPayload} payload
   */
  @Action({ rawError: true })
  async copyReport (payload: ICopyReportPayload) {
    const response = await Container.get(ReportService).copyReportToEvent(payload)
    return response
  }

  /**
   * Sends an import request to the backend. The backend will then process the request and return a response.
   * @param {IImportReportPayload} payload
   */
  @Action({ rawError: true })
  async importReport (payload: ICopyReportPayload) {
    const response = await Container.get(ReportService).importReportToCurrentEvent(payload)
    return response
  }
}
