import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Container from 'typedi'
import SurveyService from '../services/surveys.service'
import { ParticipantSurveys } from '@/interfaces'

@Module({
  namespaced: true
})
export default class SurveyStore extends VuexModule {
  sessionChangelog: any = {}
  generalChangelog: any = {}
  keynoteChangelog: any = {}
  sessionSurvey: any = {}
  generalSurvey: any = {}
  keynoteSurvey: any = {}
  // TODO: define an explicit type for this.
  participantSurveys: ParticipantSurveys = {}
  surveyStats: any = {}
  surveysSummaries: any = []

  currentlySessionDeployedLiveUUID: any = {}
  currentSessionDevUUID: any = {}
  currentlyGeneralDeployedLiveUUID: any = {}
  currentGeneralDevUUID: any = {}
  currentlyKeynoteDeployedLiveUUID: any = {}
  currentKeynoteDevUUID: any = {}
  certificates: any = {}

  get activeCertificate () {
    return this.certificates.certificates?.find(certificate => certificate.active) ?? null
  }

  get completedParticipantSurveys () {
    const surveys = Object.values(this.participantSurveys).flat()
    return surveys.filter((survey: any) => survey.filled_date)
  }

  get incompleteParticipantSurveys () {
    const surveys = Object.values(this.participantSurveys).flat()
    return surveys.filter((survey: any) => !survey.filled_date)
  }

  // TODO: define an explicit type for the payload.
  @Mutation
  SET_PARTICIPANT_SURVEYS (payload: Record<string, any>): void {
    this.participantSurveys = payload
  }

  @Mutation
  SET_SURVEY_STATS (payload: any): void {
    this.surveyStats = payload
  }

  @Mutation
  SET_SURVEYS_SUMMARIES (payload: any): void {
    this.surveysSummaries = payload
  }

  @Mutation
  SET_SESSION_CHANGELOG (payload: any) {
    this.sessionChangelog = payload
  }

  @Mutation
  SET_GENERAL_CHANGELOG (payload: any) {
    this.generalChangelog = payload
  }

  @Mutation
  SET_KEYNOTE_CHANGELOG (payload: any) {
    this.keynoteChangelog = payload
  }

  @Mutation
  SET_SESSION_SURVEY (payload: any) {
    this.sessionSurvey = payload
  }

  @Mutation
  SET_GENERAL_SURVEY (payload: any) {
    this.generalSurvey = payload
  }

  @Mutation
  SET_KEYNOTE_SURVEY (payload: any) {
    this.keynoteSurvey = payload
  }

  @Mutation
  SET_CURRENT_SESSION_DEPLOYED_LIVE_UUID (payload: any) {
    this.currentlySessionDeployedLiveUUID = payload
  }

  @Mutation
  SET_CURRENT_SESSION_DEV_UUID (payload: any) {
    this.currentSessionDevUUID = payload
  }

  @Mutation
  SET_CURRENT_GENERAL_DEPLOYED_LIVE_UUID (payload: any) {
    this.currentlyGeneralDeployedLiveUUID = payload
  }

  @Mutation
  SET_CURRENT_GENERAL_DEV_UUID (payload: any) {
    this.currentGeneralDevUUID = payload
  }

  @Mutation
  SET_CURRENT_KEYNOTE_DEPLOYED_LIVE_UUID (payload: any) {
    this.currentlyKeynoteDeployedLiveUUID = payload
  }

  @Mutation
  SET_CURRENT_KEYNOTE_DEV_UUID (payload: any) {
    this.currentKeynoteDevUUID = payload
  }

  @Mutation
  SET_CERTIFICATES (payload: any) {
    this.certificates = payload
  }

  @Action({ rawError: true })
  async fetchSurveys ({ event_uuid, participant_uuid }: any) {
    const response = await Container.get(SurveyService).fetchSurveysForParticipant(event_uuid, participant_uuid)
    this.context.commit('SET_PARTICIPANT_SURVEYS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getCertificates ({ event_uuid, participant_uuid }: any) {
    const response: any = await Container.get(SurveyService).getCertificates(event_uuid, participant_uuid)
    this.context.commit('SET_CERTIFICATES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async getCertificatesExport ({ event_uuid, participant_uuid, certificate_uuid }: any): Promise<void> {
    const response: any = await Container.get(SurveyService).getCertificateAndTranscript(event_uuid, participant_uuid, certificate_uuid)
    return response.data
  }

  @Action({ rawError: true })
  async getSessionChangelog (payload: any) {
    const { event_uuid } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getAllContentByType(event_uuid, 'survey', 'session')
      this.context.commit('SET_SESSION_CHANGELOG', response.data)
      await this.context.dispatch('getCurrentSessionDevUUID', {
        event_uuid,
        type: 'survey',
        sub_type: 'session'
      })
      return response.data
    }
  }

  @Action({ rawError: true })
  async getGeneralChangelog (payload: any) {
    const { event_uuid } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getAllContentByType(event_uuid, 'survey', 'general')
      this.context.commit('SET_GENERAL_CHANGELOG', response.data)
      this.context.dispatch('getCurrentGeneralDevUUID', {
        event_uuid,
        type: 'survey',
        sub_type: 'general'
      })
      return response.data
    }
  }

  @Action({ rawError: true })
  async getKeynoteChangelog (payload: any) {
    const { event_uuid } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getAllContentByType(event_uuid, 'survey', 'keynote')
      this.context.commit('SET_KEYNOTE_CHANGELOG', response.data)
      this.context.dispatch('getCurrentKeynoteDevUUID', {
        event_uuid,
        type: 'survey',
        sub_type: 'keynote'
      })
      return response.data
    }
  }

  @Action({ rawError: true })
  async getSessionSurvey (payload: any) {
    const { event_uuid } = payload
    try {
      const response = await Container.get(SurveyService).getSurvey(event_uuid, 'survey', 'session')
      this.context.commit('SET_SESSION_SURVEY', response.data)
      return response.data
    } catch (error) {
      if (error.data.error_code === 'CONTENT_NOT_FOUND') {
        this.context.commit('SET_SESSION_SURVEY', {})
      }
    }
  }

  @Action({ rawError: true })
  async getGeneralSurvey (payload: any) {
    const { event_uuid } = payload
    try {
      const response = await Container.get(SurveyService).getSurvey(event_uuid, 'survey', 'general')
      this.context.commit('SET_GENERAL_SURVEY', response.data)
      return response.data
    } catch (error) {
      if (error.data.error_code === 'CONTENT_NOT_FOUND') {
        this.context.commit('SET_GENERAL_SURVEY', {})
      }
    }
  }

  @Action({ rawError: true })
  async getKeynoteSurvey (payload: any) {
    const { event_uuid } = payload
    try {
      const response = await Container.get(SurveyService).getSurvey(event_uuid, 'survey', 'keynote')
      this.context.commit('SET_KEYNOTE_SURVEY', response.data)
      return response.data
    } catch (error) {
      if (error.data.error_code === 'CONTENT_NOT_FOUND') {
        this.context.commit('SET_KEYNOTE_SURVEY', {})
      }
    }
  }

  // TODO(zb): These next two function are identical except for the commit. Refactor to use a single function.
  @Action({ rawError: true })
  async getCurrentlySessionDeployedLiveUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_SESSION_DEPLOYED_LIVE_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentSessionDevUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_SESSION_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getSessionSurveyByUUID (payload: any) {
    const { event_uuid, survey_uuid } = payload
    if (event_uuid && survey_uuid) {
      const response = await Container.get(SurveyService).getSurveyByUuid(event_uuid, survey_uuid)
      this.context.commit('SET_CURRENT_SESSION_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentlyGeneralDeployedLiveUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_GENERAL_DEPLOYED_LIVE_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentGeneralDevUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_GENERAL_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getGeneralSurveyByUUID (payload: any) {
    const { event_uuid, survey_uuid } = payload
    if (event_uuid && survey_uuid) {
      const response = await Container.get(SurveyService).getSurveyByUuid(event_uuid, survey_uuid)
      this.context.commit('SET_CURRENT_GENERAL_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentlyKeynoteDeployedLiveUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_KEYNOTE_DEPLOYED_LIVE_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getCurrentKeynoteDevUUID (payload: any) {
    const { event_uuid, type, sub_type } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).getUUID(event_uuid, type, sub_type)
      this.context.commit('SET_CURRENT_KEYNOTE_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async getKeynoteSurveyByUUID (payload: any) {
    const { event_uuid, survey_uuid } = payload
    if (event_uuid && survey_uuid) {
      const response = await Container.get(SurveyService).getSurveyByUuid(event_uuid, survey_uuid)
      this.context.commit('SET_CURRENT_KEYNOTE_DEV_UUID', response.data)
      return response
    }
  }

  @Action({ rawError: true })
  async saveSessionSurvey (payload: any) {
    const { event_uuid, type, sub_type, data } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).saveSurvey(event_uuid, type, sub_type, data)
      this.context.dispatch('getSessionChangelog', {
        event_uuid: event_uuid
      })
      return response
    }
  }

  @Action({ rawError: true })
  async saveGeneralSurvey (payload: any) {
    const { event_uuid, type, sub_type, data } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).saveSurvey(event_uuid, type, sub_type, data)
      this.context.dispatch('getGeneralChangelog', {
        event_uuid: event_uuid
      })
      return response
    }
  }

  @Action({ rawError: true })
  async saveKeynoteSurvey (payload: any) {
    const { event_uuid, type, sub_type, data } = payload
    if (event_uuid) {
      const response = await Container.get(SurveyService).saveSurvey(event_uuid, type, sub_type, data)
      this.context.dispatch('getKeynoteChangelog', {
        event_uuid: event_uuid
      })
      return response
    }
  }

  // TODO: define the type for the payload.
  @Action({ rawError: true })
  async fetchSurveysSummaries ({ event_uuid }: any) {
    const response = await Container.get(SurveyService).fetchSurveysSummaries(event_uuid)
    this.context.commit('SET_SURVEYS_SUMMARIES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchSurveyStats ({ event_uuid, session_uuid }: any) {
    const response = await Container.get(SurveyService).fetchSurveyStats(event_uuid, session_uuid)
    this.context.commit('SET_SURVEY_STATS', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async fetchSessionsHighlightsExport ({ event_uuid }: any) {
    const response = await Container.get(SurveyService).fetchSessionsHighlightsExport(event_uuid)
    return response
  }

  @Action({ rawError: true })
  async fetchAllSurveyedParticipantsExport (event_uuid: any) {
    const response = await Container.get(SurveyService).fetchAllSurveyedParticipantsExport(event_uuid)
    return response
  }

  @Action({ rawError: true })
  async fetchAllAnswersExport ({ event_uuid, session_uuid }: any) {
    const response = await Container.get(SurveyService).fetchAllAnswersExport(event_uuid, session_uuid)
    return response
  }

  @Action({ rawError: true })
  async resetSurveyMaxAttempts ({ event_uuid, participant_uuid, session_uuid }: any) {
    const response = await Container.get(SurveyService).resetSurveyMaxAttempts(event_uuid, participant_uuid, session_uuid)
    return response
  }

  @Action({ rawError: true })
  async downloadPdf ({ event_uuid, session_uuid }: any) {
    const response = await Container.get(SurveyService).downloadPdf(event_uuid, session_uuid)
    return response
  }

  @Action({ rawError: true })
  async getEventCertificates ({ event_uuid }: any) {
    const response: any = await Container.get(SurveyService).getEventCertificates(event_uuid)
    this.context.commit('SET_CERTIFICATES', response.data)
    return response.data
  }

  @Action({ rawError: true })
  async startAllCertificatesAndTranscriptsExport (payload: any) {
    const { event_uuid, certificate_uuid } = payload
    const response: any = await Container.get(SurveyService).startAllCertificatesAndTranscriptsExport(event_uuid, certificate_uuid)
    return response
  }

  @Action({ rawError: true })
  async getAllCertificatesAndTranscriptsExport (payload: any): Promise<void> {
    const { event_uuid, certificate_uuid } = payload
    const response: any = await Container.get(SurveyService).getAllCertificatesAndTranscriptsExport(event_uuid, certificate_uuid)
    return response
  }
}
