import IHttpClientPostParameters from '../../contracts/http-client/http-client-post-parameters.interface'
import IHttpClientGetParameters from '../../contracts/http-client/http-client-get-parameters.interface'
import HttpClient from '../http-client.service'
import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import IHttpClientDeleteParameters from '../../contracts/http-client/http-client-delete-parameters.interface'
import FileUploadService from '../file-upload.service'
import IHttpClientPatchParameters from '../../contracts/http-client/http-client-patch-parameters.interface'

@Service()
export default class EventService {
  public async getMcProFields (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/mcpro-fields`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getParticipantFields (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/fields/participants?filtered=true`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSessionFields (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/fields/sessions`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSessionAttendeeFields (event_uuid: string): Promise<AxiosResponse> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/fields/session_attendees`,
      requireToken: true
    }
    return Container.get(HttpClient).get(httpParams)
  }

  public async createEvent (event: any): Promise<any> {
    const _event = { ...event }
    if (_event.banner) {
      const banner = await Container.get(FileUploadService).uploadFile(event.banner)
      _event.banner = banner
    }

    if (_event.favicon) {
      const favicon = await Container.get(FileUploadService).uploadFile(event.favicon)
      _event.favicon = favicon
    }

    const httpParams: IHttpClientPostParameters = {
      url: '/event',
      requireToken: true,
      payload: _event
    }
    const response = await Container.get(HttpClient)
    return response.post(httpParams)
  }

  // TODO: Rename this. This call actually returns the companies, the events are in the payload to. But the name is misleading.
  public async getEvents (): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: 'events?skip_stats=true',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getEventsForCompany (company_uuid: string): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `events?filter_company_uuid=${company_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getEvent (uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${uuid}/setup/default`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getEventOnly (uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async loadEvent (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async fetchStatsSummary (event_uuid: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/overview-stats`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response?.data
  }

  public async getEventStats (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/stats`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getEventModules (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/modules`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async activateModule (event_uuid: string, body: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/modules`,
      requireToken: true,
      payload: body
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async deactivateModule (event_uuid: string, data: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/modules`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getTimezone (latitude: string, longitude: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/timezone/${latitude}/${longitude}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async removeEvent (event_uuid: string, data: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async changeEventStatus (event_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getChangelog (event_uuid: string, type: string, subtype: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/${type}/${subtype !== null ? subtype : ''}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async updateEvent (event_uuid: string, data: any) {
    if (data.uploadNewBanner) {
      data.banner = await Container.get(FileUploadService).uploadFile(data.banner)
    }
    if (data.uploadNewFavicon) {
      data.favicon = await Container.get(FileUploadService).uploadFile(data.favicon)
    }
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteUser (user_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/user/${user_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async loadUsers () {
    const httpParams: IHttpClientGetParameters = {
      url: '/users',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getDeletedEvents () {
    const httpParams: IHttpClientGetParameters = {
      url: '/events/deleted',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getDeletionRequests () {
    const httpParams: IHttpClientGetParameters = {
      url: '/events/deletion_requests',
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async undeleteEvent (event_uuid: string) {
    const httpParams: IHttpClientPatchParameters = {
      url: '/events/undelete',
      requireToken: true,
      payload: event_uuid
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async updateDeletionRequest (deletion_request_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `events/deletion_request/${deletion_request_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  // WEB HOOKS

  public async getWebhookSent (event_uuid: string, webhook_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/webhook/${webhook_uuid}/sent`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getWebhook (event_uuid: string, webhook_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/webhook/${webhook_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getWebhooks (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/webhooks`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deleteWebhook (event_uuid: string, webhook_uuid: string, data: any) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/webhook/${webhook_uuid}`,
      requireToken: true,
      config: {
        data
      }
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async createWebhook (event_uuid: string, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/webhook`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async resendWebhook (event_uuid: string, webhook_uuid: string, webhook_sent_uuid: string): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/webhook/${webhook_uuid}/sent/${webhook_sent_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateWebhook (event_uuid: string, webhook_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/webhook/${webhook_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getSupportChecks (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/checks`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSupportCheck (event_uuid: string, check_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/checks/check/${check_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async getSupportCheckResults (event_uuid: string, check_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/checks/check/${check_uuid}/results`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async createSupportCheck (event_uuid: string, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/checks/check`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateSupportCheck (event_uuid: string, check_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/checks/check/${check_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getUUID (event_uuid: string, type: string, sub_type: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/content/versions/${type}/${sub_type}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async deployLive (event_uuid: string, type: string, sub_type: string, data: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/content/${type}/${sub_type}/environment/live`,
      requireToken: true,
      payload: data,
      config: {
        headers: {
          environment: 'live'
        }
      }
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async eventIdExists (event_identifier: string): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `/event-identifier/${event_identifier}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async dupeEvent (event_uuid: string, event: any): Promise<any> {
    if (event.banner) {
      const banner = await Container.get(FileUploadService).uploadFile(event.banner)
      event.banner = banner
    }

    if (event.favicon) {
      const favicon = await Container.get(FileUploadService).uploadFile(event.favicon)
      event.favicon = favicon
    }

    const httpParams: IHttpClientPostParameters = {
      url: `/event-duplicate/${event_uuid}`,
      requireToken: true,
      payload: event
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getEventActivityLog (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/activity`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async addScanToParticipant (event_uuid: string, participant_uuid: string, session_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/participant/${participant_uuid}/sessions/${session_uuid}/attendance`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async loadEventCode (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/scantrakk/codes`,
      requireToken: true
    }
    return await Container.get(HttpClient).get<any>(httpParams)
  }

  public async loadPrinterCode (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/printer/codes`,
      requireToken: true
    }
    return await Container.get(HttpClient).get<any>(httpParams)
  }

  public async loadCustomFields (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/fields/participants?filtered=true`,
      requireToken: true
    }
    return await Container.get(HttpClient).get<any>(httpParams)
  }

  public async addCustomField (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/fields?filtered=true`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateCustomField (event_uuid: string, data: Record<string, any>): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/field/${data.uuid}`,
      requireToken: true,
      payload: data
    }

    const response: AxiosResponse<any> = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteCustomField (event_uuid: string, field_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `/event/${event_uuid}/field/${field_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async fetchBookmarks (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `/event/${event_uuid}/bookmarks`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }

  public async addBookmark (event_uuid: string, data: any) {
    const httpParams: IHttpClientPostParameters = {
      url: `/event/${event_uuid}/bookmarks`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async updateBookmark (event_uuid: string, bookmark_uuid: string, data: any) {
    const httpParams: IHttpClientPatchParameters = {
      url: `/event/${event_uuid}/bookmarks/${bookmark_uuid}`,
      requireToken: true,
      payload: data
    }
    const response: any = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async getRegistrationTypeCounts (event_uuid: string) {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/participants-reg-type-counts`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).get(httpParams)
    return response
  }
}
