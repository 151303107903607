import Container, { Service } from 'typedi'
import { AxiosResponse } from 'axios'
import HttpClient from '@/modules/common/services/http-client.service'
import IHttpClientGetParameters from '@/modules/common/contracts/http-client/http-client-get-parameters.interface'
import IHttpClientPostParameters from '@/modules/common/contracts/http-client/http-client-post-parameters.interface'
import IHttpClientDeleteParameters from '@/modules/common/contracts/http-client/http-client-delete-parameters.interface'
import IHttpClientPatchParameters from '@/modules/common/contracts/http-client/http-client-patch-parameters.interface'
import { PlatformReportsChartDataTypesEnum } from '@/modules/level-one/enums/platform-reports-chart-data-types.enum'
import { PlatformReportsLeaderboardsType } from '@/modules/level-one/types/platform-reports-leaderboards.type'
import { ICopyReportPayload } from '@/interfaces/reports.interface'

@Service()
export default class ReportService {
  public async getPlatformReportsStats (daysBack: number) {
    const httpParams: IHttpClientGetParameters = {
      url: `platform-reporting/stats?days_back=${daysBack}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportPlatformReportsStats (daysBack: number) {
    const httpParams: IHttpClientGetParameters = {
      url: `platform-reporting/stats-export?days_back=${daysBack}&file_type=xlsx`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getPlatformReportsChartData (daysBack: number, type: PlatformReportsChartDataTypesEnum) {
    const httpParams: IHttpClientGetParameters = {
      url: `platform-reporting/chart-data?days_back=${daysBack}&items[]=${type}`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getPlatformReportsLeaderboards () {
    const httpParams: IHttpClientGetParameters = {
      url: 'platform-reporting/leaderboard',
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async getReports (event_uuid: string): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/reports`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async addReport (data: any, event_uuid: string): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/report`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async exportPlatformReportsChartData (payload: any): Promise<any> {
    const httpParams: IHttpClientPostParameters = {
      url: 'platform-reporting/freeform-export?file_type=xlsx',
      requireToken: true,
      payload
    }
    return await Container.get(HttpClient).post(httpParams)
  }

  public async exportPlatformLeaderboard (type: PlatformReportsChartDataTypesEnum): Promise<any> {
    const httpParams: IHttpClientGetParameters = {
      url: `platform-reporting/leaderboard/${type}/export?file_type=xlsx`,
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async exportPlatformReportsLeadsSales () {
    const httpParams: IHttpClientGetParameters = {
      url: 'platform-reporting/leads-sales-export',
      requireToken: true
    }
    return await Container.get(HttpClient).get(httpParams)
  }

  public async updateReport (data: any, event_uuid: string, report_uuid: string): Promise<any> {
    const httpParams: IHttpClientPatchParameters = {
      url: `event/${event_uuid}/report/${report_uuid}`,
      requireToken: true,
      payload: data
    }
    const response = await Container.get(HttpClient).patch(httpParams)
    return response
  }

  public async deleteReport (event_uuid: string, report_uuid: string) {
    const httpParams: IHttpClientDeleteParameters = {
      url: `event/${event_uuid}/report/${report_uuid}`,
      requireToken: true
    }
    const response: any = await Container.get(HttpClient).delete(httpParams)
    return response
  }

  public async getReport (event_uuid: string, report_uuid: string): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/report/${report_uuid}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getReportSnapshot (event_uuid: string, report_uuid: string) {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/report/${report_uuid}/snapshot`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).post(httpParams)
    return response
  }

  public async getReportSnapshotResult (event_uuid: string, report_uuid: string, snapshot_uuid: string): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/report/${report_uuid}/snapshot/${snapshot_uuid}?hide_data=true`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  public async getReportSnapshotResultData (event_uuid: string, report_uuid: string, snapshot_uuid: string, page: string): Promise<AxiosResponse<any>> {
    const httpParams: IHttpClientGetParameters = {
      url: `event/${event_uuid}/report/${report_uuid}/snapshot/${snapshot_uuid}/data?page=${page}`,
      requireToken: true
    }
    const response = await Container.get(HttpClient).get<any>(httpParams)
    return response
  }

  // copyReport will copy a report to a new event
  public async copyReportToEvent ({
    source_event_uuid: event_uuid,
    target_event_uuid,
    report_uuid,
    report_name
  }: ICopyReportPayload) {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/report/${report_uuid}/copy/${target_event_uuid}`,
      requireToken: true,
      payload: {
        report_name
      }
    }
    const response = await Container.get(HttpClient).post<any>(httpParams)
    return response
  }

  // TODO(z): This is very similar to above copy method. I like that each function does one thing, but we could consider refactoring to a single function that takes a flag to determine which action to take
  /**
   * Similar to copyReportToEvent, but will copy or "import" a report to the current event
   * @param event_uuid
   * @param target_event_uuid
   * @param report_uuid
   * @param report_name
   */
  public async importReportToCurrentEvent ({
    source_event_uuid: event_uuid,
    target_event_uuid,
    report_uuid,
    report_name
  }: ICopyReportPayload) {
    const httpParams: IHttpClientPostParameters = {
      url: `event/${event_uuid}/reports/${target_event_uuid}/import/${report_uuid}`,
      requireToken: true,
      payload: {
        report_name
      }
    }
    const response = await Container.get(HttpClient).post<any>(httpParams)
    return response
  }
}
